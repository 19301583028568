@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }

  html,
  body {
    height: 100%;
  }

  a {
    @apply underline;
  }

  @keyframes animation {
    0% {
      transform: rotate(-1deg);
    }
    100% {
      transform: rotate(1deg);
    }
  }

  .animation {
    animation: animation 0.1s infinite;
  }
}
